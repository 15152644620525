#header {
	display: flex;
	flex-wrap: wrap;
	padding: 0 25px 13px;
	padding-top: 15px;
}
.left_hdr {
	flex-grow: 1;
	display: flex;
	align-content: center;
	flex-wrap: wrap;
}
.middle_hdr {
	flex-grow: 5;
}
.right_hdr {
	flex-grow: 2;
}
/*
.right_hdr {
	visibility: hidden;
}
 */
.menuCall {
	width: 22px;
	float: left;
	padding-right: 25px;
}
.menuCall img {
	width: 100%;
}
.icon {
	width: 85px;
	float: left;
}
.icon a {
	width: 100%;
}
.icon img {
	width: 100%;
}

.searchBox {
	width: 77%;
	margin: auto;
	display: flex;
	height: 33px;
	flex-wrap: nowrap;
	border: thin solid #ccc;
}
.searchBox input {
	flex-grow: 8;
	border: none;
	padding: 0 10px;
	font-size: 16px;
	box-shadow: 0 0px 4px 0px #ccc;
}
.searchBox button {
	flex-grow: 1;
	border: none;
	border-left: thin solid #ccc;
	background: #f8f8f8;
	cursor: pointer;
}
.searchBox button img {
	width: 13px;
}
.avatar_1 {
	float: right;
	background: none;
	display: flex;
	color: #065fd4;
	font-size: 14px;
	border: thin solid #065fd4;
	padding: 4px 9px;
	border-radius: 4px;
	cursor: pointer;
}
.avatar_1 img {
	border-radius: 90px;
	border: thin solid gray;
}
.avatIcon {
	width: 32px;
}
.avatar_1.loginAvt {
	border: none;
	padding-top: 0;
	padding-bottom: 0;
}
.avatar_1 span {
	align-self: center;
	padding: 0 6px;
}
/* mobile header */

/*=========== header ===========*/
.hdr_2 {display: none;}
.hdr_1 {
	display: flex;
	width: 100%;
}
.srcBtn {
	display: flex;
	justify-content: end;
}
.srcBtn button {
	background: none;
	border: none;
	cursor: pointer;
}
/*
* login user icon
*/
.singIn {
	background: no-repeat;
	border: 1px solid #3ca7db;
	padding: 6px 6px;
	display: flex;
	width: 98px;
	float: right;
}
.singIn img {
	width: 20px;
}
.singIn span {
	align-self: center;
	flex-grow: 4;
	color: #3ca7db;
	font-weight: bold;
}
.middle_hdr.left.mobSearchBar {
	display: flex;
	margin-bottom: 5px;
}
.backBtn {
	background: none;
	border: none;
	cursor: pointer;
}