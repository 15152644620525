
@media only screen and (max-width: 1169px) {
	.containers {width: 980px;}
}

/*		tab: 480px.   */
@media only screen and (max-width: 979px) {

	.vid_Info {
		flex: 48% !important;
	}
	.vidIframe {
		height: 260px !important;
	}
	.thumb_1 {
		width: 100% !important;
	}
	.rltVidInfo {
		width: 100% !important;
		padding-top: 10px !important;
	}
	.related_video {
		margin-bottom: 30px;
	}
}


/*		Mobile Layout: 480px.   */
@media only screen and (max-width: 767px) {
	/* header */
	.icon.mobIcon {
		margin: 0;
	}
	.menuCall {
		display: none;
	}

	.right_hdr {
		margin-bottom: 7px;
	}
	.right_hdr a {
		float: none;
		margin: auto;
		display: table;
	}

	.searchBox {
		width: 86%;
	}
	.loading {
		width: 100%;
	}
	.loading img {
		width: 96%;
		display: table;
		margin: auto;
	}
	/* header end */
	.menuList a span {display: none;}
	.icon {
		float: none;
		margin: auto;
		margin-bottom: 10px;
	}
	.vid_Info {
		flex: 100% !important;
		margin-right: 0px !important;
	}
	.sng_video_wrap {
		padding: 20px;
	}
	.sng_video_wrap {
		flex-wrap: wrap;
	}
	.vidIframe {
		height: 170px !important;
	}
	.video_view {
		flex: 100%;
		padding-right: 0px;
		margin-bottom: 50px;
	}
	.vidIframe {
		height: 170px !important;
	}
	/* single page*/ 
	.related_video {
		margin-bottom: 15px;
	}
	.thumb_1 {
		width: 40% !important;
	}
	.rltVidInfo {
		width: 60% !important;
		padding-top: 0px !important;
		margin-top: 0px;
	}
	.channelInfo {
		padding-top: 5px;
	}
/*mobile home view*/
.vid_content_wrap {width: 100% !important;}	
.vid_content {padding: 0px !important; background: #fff !important; padding-bottom: 30px !important;}
.channelInfo { font-size: 13px !important;float: left !important;padding: 0 10px 0 0 !important;}
.viewTime {float: left !important;font-size: 13px !important;}
.info_1 {padding: 0 10px !important;}
.vid_Info {margin-bottom: 25px !important;}
.MobileSidebar_wrap { position: fixed; bottom: 0; width: 100%; background-color: #fff; border-top: thin solid #e5e5e5;}
.mobileSidebar {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
}
.listOfMenu {
	width: 100%;
}
.listOfMenu a {
	display: flex;
	text-decoration: none;
	padding: 5px 0 3px 0;
	flex-direction: column;
	color: #686868;
}
.listOfMenu.active a {
	color: red;
}
.listOfMenu a img {
	width: 15px;
	height: 15px;
	margin: auto;
}
.listOfMenu a span { font-size: 10px; padding-top: 3px; margin: auto; font-weight: 100;}
/* .listOfMenu:hover a {color: red !important;} */

/* single page */
.sng_video_wrap {
	padding: 0px !important;
	flex-direction: column;
}
.video_view {
	padding-right: 0px !important;
	display: flex;
	flex-direction: column;
}
.title_2, .viewTime, .vidDec  {
	padding: 0 10px;
}
.hr_1 {
	margin: 0px; margin-top: 17px;
}
#releatedVid_result {
	padding-bottom: 35px;
}
}

/*		Mobile Layout: 320px.   */
@media only screen and (max-width: 439px) {
}

