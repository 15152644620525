#dashboard {
	display: flex;
	flex-direction: column;
}
#ds_header {
	display: flex;
}
.dsLogo {
	width: 20%;
	display: flex;
	padding: 8px 30px;
}
.dsLogo img {
	width: 104px;
	margin: auto;
	height: auto !important;
}
.dsTitle {
	width: 80%;
	display: flex;
}
.dsTitle h2 {
	padding: 15px 15px !important;
	margin: 0px;
	font-size: 20px;
	align-self: center;
	padding: 0 11px;
}
#dsBodyWrap {
	display: flex;
	min-height: 90vh;
}
.ds_sidebar {
	width: 20%;
	display: flex;
	flex-direction: column;
	margin-top: 17px;
}
.ds_sidebar a {
	display: flex;
	font-size: 15px;
	text-decoration: none;
	padding: 8px 30px;
	color: #606060;
}
.ds_sidebar a > svg {
	align-self: center;
	width: 15px;
	margin-right: 10px;
}
.ds_sidebar a > span {
	align-self: center;
}
/* dropdown menu*/
.menuWrapper {
	list-style: none;
	padding: 0;
	width: 100%;
}
.menuWrapper a {
	text-decoration: none;
}
.menuWrapper li > ul {
	list-style: none;
	padding: 0;
	display: none;
}
.menuWrapper li > ul li {
	background: #f3f3f3;
	padding-left: 15px;
}
.span_1 {
	cursor: pointer;
	padding: 8px 30px;
	font-size: 15px;
	color: #606060;
	display: flex;
}
/**/
.bodyContent {
	width: 80%;
	background: #f4f7fc;
	padding: 15px 50px;
}
.AddForm {
	background-color: #fff;
	padding: 20px;
}
.AddForm .MuiFormControl-root.MuiTextField-root {
	width: 100%;
}
.saveBtn {
	margin-top: 15px !important;
	padding: 5px 44px !important;
}
.noteInput {
	font-size: 12px;
	color: #606060;
}
.ds_sidebar .active {
	color: #27bf44;
}

/*
* allChennel Page 
*/
.approve, .disline, .delete, .allow {
	border: none;
	padding: 6px 15px;
	cursor: pointer;
}
.approve {
	background: #39acea;
	color: #fff;
	margin-right: 10px;
}
.disline {
	background: #ff6464;
	color: #fff;
	margin-right: 10px;
}
.allow{
	background: #27bf44;
	color: #fff;
	margin-right: 10px;}
.delete {
	background: #e60000;
	color: #fff;
	margin-right: 10px;
}

.showMenu { display: block !important;}
.submenuIconUp, .submenuIconDown {
	margin-left: 5px;
}
.span_1 .vidIcon {
	width: 15px;
	margin-right: 10px;
	margin-top: -2px;
}
.sideBar {
	display: flex;
	flex-direction: column;
	width: 20%;
}
.avatar_3 {
	width: 50px;
}