
/* 
*main content body
*/
#content_wrap {
	float: left;
	width: 100%;
}
.sidebar {
	float: left;
	width: 18%;
}
.historyLogin {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 50vh;
	padding-top: 80px;
}
.histInfo {
	color: #5c5c5c;
	padding-bottom: 15px;
}
.vid_content_wrap {
	float: left;
	width: 82%;
}
.topBar {
	border-bottom: thin solid #e5e5e5;
	/* border-top: thin solid #e5e5e5; */
}
	/* sidebar*/
.sideMenu {
	list-style: none;
	padding: 0;
	margin: 0;
}
.menuList.active {background-color: #e5e5e5;}
.menuList a {
	display: flex;
	align-content: center;
	text-decoration: none;
	padding: 10px 0 10px 20px;
}
.menuList:hover {background-color: #e5e5e5;}
.menuList.active:hover {background-color: #d2d2d2;}
.menuList a img {
	float: left;
	width: 18px;
	height: 18px;
	padding-right: 20px;
}
.menuList a span {
	font-size: 14px;
	float: left;
	padding-top: 3px;
	color: #686868;
}
	/* video content area*/

.vid_content {
	display: flex;
	flex-wrap: wrap;
	background: #f9f9f9;
	padding: 20px;
}
.vid_Info {
	flex: 24% 1;
	margin-right: 1%;
	margin-bottom: 45px;
	cursor: pointer;
	text-decoration: none;
}
/*
.vid_Info:nth-child(4n) {
	margin-right: 0px;
}
*/
.vid_Info img {
	width: 100%;
}
.info_wrap {
	display: flex;
	padding-top: 10px;
}
.brand_icon {
	flex-grow: 2;
}
.info_1 {
	flex-grow: 8;
	padding-top: 5px;
}
.title_1 {
	font-size: 14px;
	padding: 0;
	margin: 0;
	color: #000000;
}
.channelInfo {
	color: #606060;
	margin: 0;
	font-size: 14px;
	padding-top: 10px;
}
.viewTime {
	color: #606060;
	margin: 0;
	list-style: none;
	padding: 0;
	font-size: 14px;
}
.view_1 {
	float: left;
}
.view_1::after {
	content: "•";
	margin: 0 9px 0 4px;
}
.viewTime {
	color: #606060;
	list-style: none;
	font-size: 14px;
}
/**** end home page ****/


/*===== youtube search result page ===========*/

.vid_content_search {
	display: flex;
	flex-wrap: wrap;
	background: #f9f9f9;
	padding: 20px;
	flex-direction: column;
}
.vid_Info_src {
	margin-bottom: 15px;
	cursor: pointer;
	width: 100%;
}
.vid_content_search img {
	width: 34%;
	float: left;
}
.info_wrap_src {
	float: right;
	width: 64%;
}
.title_2_src {
	padding: 0;
	margin: 0;
	font-weight: normal;
	color: #000000;
}
.viewTime_2 {
	list-style: none;
	margin: 0;
	padding: 0;
	margin-top: 10px;
	color: #606060;
	font-size: 14px;
}
.view_2 {
	float: left;
	padding-right: 15px;
}
.view_2::after {
	content: "•";
	margin: 0 9px 0 4px;
}
.channelBrand_2 {
	float: left;
	width: 100%;
	margin-top: 15px;
}
.brand_icon_2 {
	float: left;
	margin-right: 10px;
}
.brand_icon_2 img {
	width: 30px;
}
.channelInfo_2 {
	font-size: 14px;
	color: #606060;
	float: left;
	padding-top: 5px;
	margin: 0;
}
.vid_des {
	color: #606060;
	font-size: 14px;
	margin-top: 15px;
	float: left;
	width: 100%;
}
.loading_2 img {
	width: 100px;
	margin: auto;
	display: table;
	float: none !important;
	padding-top: 150px;
	padding-bottom: 150px;
}
/*profiel page*/
.profileWrap {
	display: flex;
	flex-direction: column;
	width: 100%;
}
.profileTop {
	display: flex;
	/* background: red; */
}
.profilePicture {
	padding-right: 15px;
}
.nameEmail h3 {
	margin: 0;
	margin-bottom: 0px;
	font-size: 26px;
}
.nameEmail p {
	margin-top: 5px;
	color: #7a7a7a;
}

.profileBottom {
	padding-top: 10px;
	display: flex;
	flex-direction: column;
}
.information_2 {
	display: flex;
	padding: 10px 0;
	border-bottom: thin solid #d2d2d2;
}
.inforType {
	width: 130px;
}
.nodataFound {
	text-align: center;
}