.emptyFiled{background-color: #ff6464 !important;}
.headingMissInfo {
	margin: 8px;
}
.missingInfo {
	position: fixed;
	width: 100%;
	height: 100%;
	background: #fff;
	left: 0;
	top: 0;
	opacity: 0.97;
	display: flex;
	justify-content: center;
}
.missingWrap {
	align-self: center;
}
.formHeading {
	margin: 0;
	font-size: 13px;
	color: #939393;
	padding-top: 15px;
	margin-left: 8px;
}
.missingField.saveBtnWrap {
	margin: 8px;
}
.saveMissingBtn {
	width: 100%;
	height: 58px;
}
#waring_2 {
	font-size: 14px;
	color: red;
}
/*Admin Login*/

#adminLoginWrap {
	display: flex;
	align-items: center;
	width: 100%;
	min-height: 100vh;
}
#adminLoginWrap .avatar_1, #adminLoginWrap h3 {
	margin: auto;
}