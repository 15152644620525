
/*single page */

.sng_video_wrap {
	background: #f9f9f9;
	padding: 20px 30px 20px 40px;
	display: flex;
	flex-wrap: nowrap;
}
.video_view {
	flex: 65%;
	padding-right: 25px;
}
.releatedVid_wrap {
	flex: 30%;
}
.vidIframe {
	width: 100%;
	height: 480px;
}
.title_2 {
	font-size: 17px;
	font-weight: normal;
}
.related_video {
	float: left;
	width: 100%;
	margin-bottom: 10px;
	text-decoration: none;
}
.thumb_1 {
	width: 45%;
	float: left;
}
.rltVidInfo {
	width: 55%;
	padding-top: 0px !important;
}
.rldInfo {
	padding-left: 10px !important;
}
.related_video:first-child {
	display: none;
}
.related_video.fireReltedtVid:first-child {
	display: block;
}
.hr_1 {border: thin solid #dfdfdf;}